<template>
    <modal ref="agregarEntrega" titulo="Añadir entrega" adicional="Añadir" @adicional="agregarEntrega">
        <div class="container m-3">
           <div class="row justify-content-center">
               <div class="col-9 mb-3">
                   <p class="input-label-top">Fecha de entrega</p>
                   <el-date-picker v-model="datePicker" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
               </div>
               <div class="col-9 mb-3">
                   <p class="input-label-top">Unidades del material</p>
                   <el-input v-model="cantUnidadesMaterial" class="w-100" size="small" placeholder="Cantidad de unidades" />
               </div>
           </div>
           
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            datePicker: '',
            cantUnidadesMaterial: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.agregarEntrega.toggle()
        },
        agregarEntrega(){
            this.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
