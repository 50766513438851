<template>
    <section class="compras-ver-orden px-3">
        <navbar-admin titulo="Compras > Orden de compra 45424">
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2"
                    :class="permitAction('almacen.materiales.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="functionPermitAction('almacen.materiales.eliminar', openModalEliminarOrdenCompra)"
                >
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <div class="row my-4">
            <div class="col-12 mb-md-3 col-lg-9">
                <!-- card-orden-compra -->
                <card-orden-compra
                    :orderNumber="purchaseOrderInfo.id"
                    :state="purchaseOrderInfo.estado"
                    :date="purchaseOrderInfo.fecha | helper-fecha('DD MMM. YYYY')"
                    :supplier="purchaseOrderInfo.proveedor && purchaseOrderInfo.proveedor.nombre"
                    :value="purchaseOrderInfo.valor"
                    :destiny="purchaseOrderInfo.destino"
                    :paymentMethod="'-'"
                    :client="purchaseOrderInfo.oportunidad && purchaseOrderInfo.oportunidad.cliente"
                    :project="purchaseOrderInfo.oportunidad && purchaseOrderInfo.oportunidad.nombre"
                    :service="purchaseOrderInfo.tipo === 1 ? 'Material' : 'Servicios'"
                    @edit="openModalFormaPago"
                />
                 <div class="bg-f9 border p-3 br-4 mb-3">
                    <!-- proveedor + forma de pago -->
                    <div class="row f-14">
                        <div class="col-12 col-lg-12">
                            <div class="d-flex" style="gap: 15px">
                                <p class="f-600">Descripción:</p>
                                <p class="f-300">{{ purchaseOrderInfo.descripcion }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="([2,3,4]).includes(purchaseOrderInfo.estado)" class="col-12 mb-md-3 col-lg-3 pl-xl-0">
                <div class="bg-f9 border px-2 py-2 br-4 position-relative">
                    <div class="bg-5d text-white f-600 text-center br-4 f-12 py-1 mb-2">
                        Asociar factura
                    </div>
                    <div class="row">
                        <div v-for="(factura, index) in purchaseOrderInfo.facturas.slice(0,4)" :key="index" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-3">
                            <div class="bg-f5 border br-4 d-middle justify-content-between px-1 f-600">
                                <p class="tres-puntos -12">Factura: {{ factura.referencia }}</p>
                                <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                    <template #content>
                                        <el-popover v-model="visibleEditarEtapa" placement="bottom-end" width="200" trigger="click" class="px-0">
                                            <div class="row mx-0 justify-content-center f-600 text-general">
                                                Editar factura
                                            </div>
                                            <hr />
                                            <div class="row mx-0 justify-content-center my-2">
                                                <div class="col-12 my-2">
                                                    <p class="input-label-top">Número de factura</p>
                                                    <el-input v-model="numeroFacturaAsociada" class="f-12 br-5 w-100" size="small" />
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="text-right m-0">
                                                <button class="btn btn-cerrar f-12" @click="visibleEditarEtapa = !visibleEditarEtapa">Cerrar</button>
                                                <button class="btn btn-crear f-12 ml-2"
                                                    :class="permitAction('cobro.cartera.eliminar.factura') ? 'cr-pointer' : 'cr-not-allowed'"
                                                    @click="functionPermitAction('cobro.cartera.eliminar.factura', editarFacturaAsociada)"
                                                 >Guardar</button>
                                            </div>
                                            <p slot="reference" class="p-2 cr-pointer item"
                                                :class="permitAction('cobro.cartera.eliminar.factura') ? 'cr-pointer' : 'cr-not-allowed'"
                                                @click="functionPermitAction('cobro.cartera.eliminar.factura', editarFacturaAsociada)"
                                            >Editar</p>
                                        </el-popover>
                                        <p class="p-2 cr-pointer item"
                                            :class="permitAction('cobro.cartera.eliminar.factura') ? 'cr-pointer' : 'cr-not-allowed'"
                                            @click="functionPermitAction('cobro.cartera.eliminar.factura', eliminarFacturaAsociada)"
                                        >Eliminar</p>
                                    </template>
                                    <i class="icon-dots-vertical f-18" />
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <p 
                    v-if="purchaseOrderInfo.facturas.length > 4" 
                    class="cr-pointer f-12 f-500 text-right position-absolute w-fit-content"
                    style="right: 25px; bottom: 2px" 
                    @click="verMasFacturas" 
                    v-text="'Ver más'"
                    />
                </div>
            </div>
        </div>
        <!-- indicador-progress-value -->
        <div class="row" v-if="([2,3,4]).includes(purchaseOrderInfo.estado)">
            <div class="col-10">
                <indicador-progress-value :totalValue="purchaseOrderInfo.valor" :actualValue="purchaseOrderInfo.valor_pagado" />
            </div>
            <div class="col-2 text-right">
                <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                    <template #content>
                        <p class="p-2 cr-pointer item"
                            :class="permitAction('cobro.cartera.reportar.pago') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('cobro.cartera.reportar.pago', reportarPago)"
                        >
                        Reportar pago</p>
                        <p class="p-2 cr-pointer item"
                            :class="permitAction('almacen.categorias.dsds') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('cobro.cartera.finalizar.pago', finalizarPago)"
                        >
                        Finalizar pago</p>
                    </template>
                    <button class="btn btn-general px-3 f-12">Actualizar pago</button>
                </el-tooltip>
            </div>
        </div>
        <titulo-divisor titulo="Materiales de ordenes de compra" class="my-4">
            <div class="row">
                <div class="col auto px-1">
                    <el-input v-model="buscarListado" placeholder="Buscar material en la lista" size="small" />
                </div>
                 <div v-if="([1,2,3]).includes(purchaseOrderInfo.estado)" class="col-auto my-auto px-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="purchaseOrderInfo.estado === 1" class="col-auto my-auto pl-1">
                    <button class="btn btn-general f-12 px-4"
                        :class="permitAction('compras.orden.compra.enviar') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('compras.orden.compra.enviar', enviarOrden)"
                    >
                    Enviar orden</button>
                </div>
                <div v-if="purchaseOrderInfo.estado === 2" class="col-auto my-auto pl-1">
                    <button class="btn btn-general f-12 px-4"
                        :class="permitAction('cobro.cartera.entrega.completa') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('cobro.cartera.entrega.completa', entregaCompleta)"
                    >Actualizar estado</button>
                </div>
                <div v-if="purchaseOrderInfo.estado === 3" class="col-auto my-auto pl-1">
                    <button class="btn btn-general f-12 px-4"
                        :class="permitAction('cobro.cartera.entrega.completa') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('cobro.cartera.entrega.completa', entregaCompleta)"
                    >Entrega completa</button>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" alturaTabla="300px" :data="purchaseOrderMaterials">
            <el-table-column label="Ref" prop="id">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos f-600 cr-pointer">{{ scope.row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="material" width="350">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.material.nombre || '-' }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.material.tipo.tipo_material }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor unitario" prop="valor_unitario" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ formatoMoney(scope.row.valor_unitario, scope.row.id_moneda) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor total" prop="total_unidades" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoney(scope.row.total_unidades, scope.row.id_moneda) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades de la orden" prop="unidades_orden" align="center">
                <template slot-scope="scope">
                    <div class="order-unities" v-if="purchaseOrderInfo.estado === 1" >
                        <el-input-number 
                        v-model="scope.row.unidades_orden" 
                        @input="scope.row.unidades_orden = Math.floor($event)" 
                        :controls="false"
                        size="mini" 
                        :min="0" 
                        />
                    </div>
                    <p v-else class="f-12 text-muted2">
                        {{ scope.row.unidades_orden }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column v-if="([3,4]).includes(purchaseOrderInfo.estado)" label="última fecha de entrega" prop="ultimaFecha" align="center">
                <template slot-scope="scope">
                    {{ scope.row.ultimaFecha || '-' }}
                </template>
            </el-table-column>
            <el-table-column v-if="purchaseOrderInfo.estado === 3" align="center">
                <template>
                    <i class="icon-plus-box f-20 text-general cr-pointer" @click="agregarEntrega" />
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-eliminar ref="abrirModalEliminarOrdenCompra" title="Eliminar orden de compra" mensaje="¿Está seguro que quiere eliminar esta orden de compra?" mensaje2 />
        <modal-eliminar ref="abrirEliminarFacturaAsociada" title="Eliminar factura" mensaje="¿Está seguro que quiere eliminar esta factura?" />
        <modal-cambiar-forma-pago ref="abrirModalCambiarFormaPago" />
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-4">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="valueSelect" placeholder="Seleccionar material" size="small" class="w-100">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarOrdenesCompras"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal ref="abrirModalEnviarOrden" titulo="Enviar orden" adicional="Aceptar" @adicional="realizarEnvioOrden">
            <div class="row m-2 f-12">
                <p class="col-12 text-center">¿Está seguro de enviar esta orden de compra?</p>
            </div>
        </modal>
        <modal ref="abrirModalEntregaCompleta" titulo="Entrega completa" adicional="Aceptar" @adicional="realizarEntregaCopmleta">
            <div class="row m-2 f-12">
                <p class="col-12 text-center">¿Está seguro que quiere actualizar el estado de su orden a entrega completa?</p>
            </div>
        </modal>
        <modal ref="abrirModalFinalizarPago" titulo="Finalizar pago" adicional="Finalizar" @adicional="finalizarPago">
            <div class="row m-3 f-12">
                <p class="col-12 text-center">¿Está seguro que quiere finalizar este pago?</p>
            </div>
        </modal>
        <modal-agregar-entrega ref="agregarEntrega" />
        <modal-reportar-pago ref="reportarPago" />
        <modal-ver-mas-facturas ref="verMasFacturas" :facturas="purchaseOrderInfo.facturas" />
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import modalCambiarFormaPago from './partials/modalCambiarFormaPago'
import modalAgregarEntrega from './partials/modalAgregarEntrega'
import modalReportarPago from './partials/modalReportarPago'
import modalVerMasFacturas from './partials/modalVerMasFacturas'
export default {
    components:{
        modalCambiarFormaPago,
        modalAgregarEntrega,        
        modalReportarPago,      
        modalVerMasFacturas,        
    },
    data(){
        return{
            checked: false,
            visibleEditarEtapa: false,
            numeroFacturaAsociada: '1234',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
            buscarListado: '',
        }
    },
    computed:{
        ...mapGetters({
            purchaseOrderInfo: 'compras/ordenes_compra/purchase_order_info',
            purchaseOrderMaterials: 'compras/ordenes_compra/purchase_order_materials',
            select_monedas: 'selects/selects/select_monedas',
        })
    },
    created(){
        this.$try(async () => {
            this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['compras.matrix'])
            await Promise.all([
                this.getPurchaseOrderInfo(this.$route.params.id_orden),
                this.getPurchaseOrderMaterials(this.$route.params.id_orden),
            ])
            this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs', [
                {
                    position: 1,
                    breadcumb: {
                        name: `Orden de Compra ${this.purchaseOrderInfo.id}`,
                        route: 'compras.ver.orden',
                        params: { id: this.purchaseOrderInfo.id}
                    }
                }
            ])
        })
        this.sync_selects(['Action_get_select_monedas']);
    },
    methods:{
        ...mapActions({
            sync_selects: 'selects/selects/sync',
            getPurchaseOrderInfo: 'compras/ordenes_compra/Action_get_purchase_order_info',
            getPurchaseOrderMaterials: 'compras/ordenes_compra/Action_get_purchase_order_materials',
            sendPurchaseOrder: 'compras/ordenes_compra/Action_send_purchase_order'
        }),
        openModalEliminarOrdenCompra(){
            this.$refs.abrirModalEliminarOrdenCompra.toggle()
        },
        eliminarOrdenCompra(){
            this.$refs.abrirModalEliminarOrdenCompra.toggle()
        },
        cambiarFormaPago(){
            this.$refs.abrirModalCambiarFormaPago.toggle()
        },
        entregaCompleta(){
            this.$refs.abrirModalEntregaCompleta.toggle()
        },
        enviarOrden(){
            this.$refs.abrirModalEnviarOrden.toggle()
        },
        realizarEntregaCopmleta(){
            this.$refs.abrirModalEntregaCompleta.toggle()
        },
        async realizarEnvioOrden(){
            await this.sendPurchaseOrder(this.$route.params.id_orden)
            this.$refs.abrirModalEnviarOrden.toggle()
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        limpiarFiltro(){
            this.$refs.abrirModalFiltros.toggle()
        },
        filtrarOrdenesCompras(){
            this.$refs.abrirModalFiltros.toggle()
        },
        agregarEntrega(){
            this.$refs.agregarEntrega.toggle()
        },
        reportarPago(){
            this.$refs.reportarPago.toggle()
        },
        finalizarPago(){
            this.$refs.abrirModalFinalizarPago.toggle()
        },
        editarFacturaAsociada(){

        },
        formatoMoney(valor, moneda){
            return `${this.select_monedas.find(el => el.id === moneda)?.sigla} $${valor}`
        },
        eliminarFacturaAsociada(){
            this.$refs.abrirEliminarFacturaAsociada.toggle()
        },
        verMasFacturas(){
            this.$refs.verMasFacturas.toggle()
        },
        openModalFormaPago(){
            console.log('forma de pago')
        }
    }
}
</script>

<style lang="scss" scoped>
.compras-ver-orden{
    .icon-dots-vertical{
        &::before{
            margin: 0px;
        }
    }
    .contenedor-progress{
        background: #DDDDDD;
        border-radius: 13px;
        height: 10px;
        position: relative;
        .progress{
            background: #26DE81;
            border-radius: 13px;
            height: 10px;
        }
        .icon-flag-triangle{
            position: absolute;
            top: -14px ;
            font-size: 22px;
        }
    }
}
</style>

<style lang="scss">
.order-unities{
    input{
        width: 70px;
    }
}
</style>